import React, { ReactElement } from "react";
import {
  Box,
  Typography,
  Grid,
  Link,
  IconButton,
  ButtonBase,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvira } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
  faLeaf,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebook,
  faPinterest,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom"; // Importăm NavLink

import logo from "../assets/images/logo_design_small.png";

const meniuOptions = [
  { name: "Despre", path: "about" },
  { name: "Servicii", path: "services" },
  { name: "Inovatii tehnologice", path: "explore" },
  { name: "Termeni și condiții", path: "/terms" },
  { name: "Politica de confidentialitate", path: "/politica" },
];

const socialMediaIcons = [faTwitter, faFacebook, faPinterest, faInstagram];

export const Footer = (): ReactElement | null => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        width: "100%",
        backgroundColor: "#24231D",
        color: "white",
        padding: 0,
        position: "relative",
        bottom: 0,
        marginTop: "10%",
      }}
    >
      <Grid
        container
        sx={{
          width: "80%",
          margin: "0 auto",
          justifyContent: "center",
          alignItems: "flex-start",
          textAlign: { xs: "center", lg: "left" },
          mt: 10,
        }}
        gap={{ xs: 5, lg: 20 }}
      >
        <Grid
          item
          md={2.5}
          sm={5}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", marginTop: 0 }}>
            <Box
              component="img"
              src={logo}
              alt="certificate settings"
              sx={{
                width: { md: "100%", xs: "200px" },
              }}
            />
          </Box>
          <Box sx={{}}>
            <Typography
              sx={{
                color: "#B0B0B0",
                fontFamily: "Manrope",
                fontSize: "12.5px",
                width: { md: "100%", xs: "100%" },
                textAlign: "justify",
              }}
            >
              E mult mai simplu să faci agricultură când ai unealta potrivită
              chiar la tine în buzunar
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "left",
              // justifyContent: "center",
              mt: { md: 3, xs: 3 },
              height: "100%",
              gap: 1,
              width: "100%",
            }}
          >
            {socialMediaIcons.map((target, index) => {
              return (
                <IconButton
                  key={index}
                  color="inherit"
                  href="#"
                  sx={{
                    backgroundColor: "#1F1E17",
                    borderRadius: "50%",
                    padding: "12px",
                    mf: "2%",
                  }}
                >
                  <FontAwesomeIcon icon={target} size={"2xs"} />
                </IconButton>
              );
            })}
          </Box>
        </Grid>

        <Grid
          item
          md={2}
          sm={5}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%", textAlign: "left" }}>
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontSize: "18px",
                fontWeight: "bold",
                color: "white",
              }}
            >
              Explorează
            </Typography>
            <Grid container sx={{ width: "100%", mt: 1 }}>
              <Box
                sx={{
                  width: "20%",
                  height: 2,
                  border: 2.2,
                  borderColor: "green",
                  mb: 2,
                  borderRadius: 2,
                }}
              />
              <Box
                sx={{
                  width: 2,
                  height: 2,
                  border: 2.2,
                  borderColor: "green",
                  mb: 2,
                  borderRadius: 2,
                  ml: 1,
                }}
              />
            </Grid>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <Box sx={{ width: "fit-content", textAlign: "left" }}>
              {meniuOptions.map((target, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", mb: 1 }}
                >
                  <FontAwesomeIcon
                    icon={faLeaf}
                    style={{ marginRight: "10px", color: "gray" }}
                    size={"sm"}
                  />

                  {target.path.startsWith("/") ? (
                    <NavLink
                      to={target.path}
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#B0B0B0",
                          fontFamily: "Manrope",
                          fontSize: "12px",
                        }}
                      >
                        {target.name}
                      </Typography>
                    </NavLink>
                  ) : (
                    <ButtonBase
                      onClick={() => {
                        const element = document.getElementById(target.path);
                        if (element) {
                          element.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          });
                        } else {
                          console.warn(`Element not found: ${target.path}`);
                        }
                      }}
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#B0B0B0",
                          fontFamily: "Manrope",
                          fontSize: "12px",
                        }}
                      >
                        {target.name}
                      </Typography>
                    </ButtonBase>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={2}
          sx={{
            display: { xs: "none", lg: "flex" },
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Box sx={{ width: "100%", mb: 1 }}>
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontSize: "18px",
                fontWeight: "bold",
                color: "white",
              }}
            >
              Contact
            </Typography>
          </Box>
          <Grid container sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "20%",
                height: 2,
                border: 2.2,
                borderColor: "green",
                mb: 2,
                borderRadius: 2,
              }}
            />
            <Box
              sx={{
                width: 2,
                height: 2,
                border: 2.2,
                borderColor: "green",
                mb: 2,
                borderRadius: 2,
                ml: 1,
              }}
            />
          </Grid>

          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                mb: 2,
              }}
            >
              <FontAwesomeIcon
                className=" mt-1"
                icon={faPhone}
                style={{ marginRight: 8, color: "#EEC044" }}
                size={"sm"}
              />
              <Typography>
                <Link
                  href="tel:0723350033"
                  sx={{
                    color: "#B0B0B0",
                    textDecoration: "none",
                    fontFamily: "Manrope",
                    fontSize: 12,
                  }}
                >
                  +40 723 350 033
                </Link>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                mb: 2,
              }}
            >
              <FontAwesomeIcon
                className=" mt-1"
                icon={faEnvelope}
                style={{ marginRight: 8, color: "#EEC044" }}
                size={"sm"}
              />
              <Typography sx={{ wordBreak: "break-all" }}>
                <Link
                  href="mailto:info@cultiva.ro"
                  sx={{
                    color: "#B0B0B0",
                    textDecoration: "none",
                    fontFamily: "Manrope",
                    fontSize: 12,
                  }}
                >
                  info@cultivaapp.ro
                </Link>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="mt-1"
                style={{ marginRight: 8, color: "#EEC044" }}
                size={"sm"}
              />
              <Typography
                sx={{
                  color: "#B0B0B0",
                  textDecoration: "none",
                  fontFamily: "Manrope",
                  fontSize: 12,
                }}
              >
                Strada Dimitrie Ralet 1, Iași 700108a
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        sx={{
          width: "100%",
          padding: "25px 0",
          backgroundColor: "#1F1E17",
          mt: 10,
        }}
        container
        alignContent={"center"}
        justifyContent={{ xs: "space-around", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <Typography
          sx={{
            fontFamily: "Manrope",
            fontSize: { xs: "12px", sm: "12px" },
            color: "#B0B0B0",
            ml: { xs: "5%", md: 0 },
            mr: { xs: "5%", md: "10%" },
            mb: { xs: 1, sm: 0 },
            textAlign: "center",
          }}
        >
          &copy; 2024 <span style={{ fontWeight: "bold" }}>Cultivá</span>, Toate
          drepturile rezervate.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: { xs: "100%", sm: "40%", md: "25%" },
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Manrope",
              fontSize: { xs: "12px", sm: "12px" },
              color: "#B0B0B0",
            }}
          >
            Termeni și condiții
          </Typography>
          <Typography
            sx={{
              fontFamily: "Manrope",
              fontSize: { xs: "12px", sm: "12px" },
              color: "#B0B0B0",
              mx: "5%",
            }}
          >
            |
          </Typography>
          <Typography
            sx={{
              fontFamily: "Manrope",
              fontSize: { xs: "12px", sm: "12px" },
              color: "#B0B0B0",
            }}
          >
            Politica de confidențialitate
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};
