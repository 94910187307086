import React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  Link,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebook,
  faPinterest,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/images/logo.png";

interface infoObject {
  icon: IconProp;
  title: string;
  value: string;
}

const info: infoObject[] = [
  {
    icon: faPhone,
    title: "Sunați oricând",
    value: "+40 723 350 033",
  },
  {
    icon: faEnvelope,
    title: "Scrieți fără ezitare",
    value: "info@cultivaapp.ro",
  },
  {
    icon: faMapMarkerAlt,
    title: "Locație",
    value: "Strada Dimitrie Ralet 1, Iași 700108",
  },
];

const socialMediaIcons = [faTwitter, faFacebook, faPinterest, faInstagram];

export const HeaderPage = () => {
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "white",
        color: "black",
        boxShadow: "none",
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          margin: 0,
          padding: 0,
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} md={12} sm={12} lg={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "center",
                  md: "center",
                  lg: "center",
                },
                alignItems: "center",
                height: "90px",
                mb: { lg: "2%" },
                mr: { xs: "2%" },
              }}
            >
              <Box
                component="a"
                href="/"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #ccc", // Bordură pentru container
                }}
              >
                <Box
                  component="img"
                  src={logo}
                  alt="certificate settings"
                  sx={{
                    width: {
                      xs: "300px",
                      sm: "300px",
                      md: "300px",
                      lg: "300px",
                    },
                    height: "auto",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xl={2} lg={2} sm={12} xs={12}>
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
                mt: { sx: 0, lg: "10px" },
                height: "100%",
              }}
            >
              {socialMediaIcons.map((target, index) => {
                return (
                  <IconButton
                    key={index}
                    color="inherit"
                    href="#"
                    sx={{
                      backgroundColor: "#F8F7F0",
                      borderRadius: "50%",
                      padding: "10px",
                      mf: "2%",
                      "&:hover": {
                        backgroundColor: "rgb(75 , 175, 71, 0.3)",
                      },
                    }}
                  >
                    <FontAwesomeIcon icon={target} size={"xs"} />
                  </IconButton>
                );
              })}
            </Box> */}
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={5} xl={5}>
            <Box
              sx={{
                display: "flex",
                justifyContent: { md: "center", xs: "center", lg: "center" },
                ml: { lg: "-30px" },
                mt: { xs: "5px", lg: 0 },
                mb: { xs: 2, sm: 0 },
                alignItems: "center",
                flexDirection: { xs: "column", md: "row", sm: "row" },
                width: "100%",
              }}
            >
              {/* {info.map((element, index) => (
                <React.Fragment key={index}>
                  {index > 0 && (
                    <Box
                      sx={{
                        width: "1px",
                        height: "70px",
                        backgroundColor: {
                          xs: "rgba(0, 0, 0, 0)",
                          lg: "rgba(0, 0, 0, 0.2)",
                        },
                        display: { xs: "none", sm: "block", md: "block" },
                        ml: { lg: 1, md: 2, sm: 1 },
                        mr: { lg: 1, md: 2, sm: 1 },
                      }}
                    />
                  )}
                  <Box
                    key={index}
                    sx={{
                      display: { xs: "flex" },
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      className="mt-1"
                      icon={element.icon}
                      style={{ fontSize: "19px", color: "4BAF47" }}
                    />
                    <Box sx={{ marginLeft: { lg: 2, md: 2, sm: 1, xs: 2 } }}>
                      <Typography
                        className="typography-grey"
                        variant="subtitle1"
                        sx={{ fontSize: "12px" }}
                      >
                        {element.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "black",
                          fontSize: { sx: "14px", lg: "12px" },
                          fontWeight: "500",
                        }}
                      >
                        {element.value}
                      </Typography>
                    </Box>
                  </Box>
                </React.Fragment>
              ))} */}
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
