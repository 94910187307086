import { Box, Grid, Typography } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import { HeaderPage } from "../Components/Header";
import { NavBar } from "../Components/NavBar";
import { Contact } from "./Contact";
import { Footer } from "../Components/Footer";
import { HeroSection } from "../Components/HeroSection";
import { OfferComponent } from "../Components/OfferComponent";
import { ExploreComponent } from "../Components/ExploreComponent";
import { ReviewComponent } from "../Components/ReviewComponent";
import { BenefiesComponent } from "../Components/BenefiesComponent";
import { MoreInformations } from "../Components/MoreInformationComponent";
import { useLocation } from "react-router-dom";

export const Base = (): ReactElement | null => {
  const location = useLocation();

  useEffect(() => {
    const scrollTo = sessionStorage.getItem("scrollTo");
    if (scrollTo) {
      setTimeout(() => {
        const element = document.getElementById(scrollTo);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          sessionStorage.removeItem("scrollTo");
        }
      }, 300);
    }
  }, [location]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* <HeaderPage /> */}
      <NavBar />
      <HeroSection />
      <OfferComponent id="services" />
      <ExploreComponent id="explore" />
      <ReviewComponent id="testimonials" />
      <BenefiesComponent />
      <Contact id="contact" />
      {/* <MoreInformations id="expand" /> */}
      <Footer />
    </Box>
  );
};
