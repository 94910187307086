import React, { ReactElement } from "react";
import { Box, Typography } from "@mui/material";

export const TermsAndConditions = (): ReactElement => {
  return (
    <Box
      sx={{
        padding: "20px",
        maxWidth: "900px",
        margin: "0 auto",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        marginTop: "100px",
        textAlign: "justify",
        px: 5,
        py: 5,
      }}
    >
      <Typography variant="body1" paragraph>
        <strong> Stimate client,</strong>
      </Typography>
      <Typography variant="body1" paragraph>
        Pentru a utiliza Aplicație Web și Aplicația Mobilă Cultivá, trebuie să
        citiți și să acceptați acești termeni și condiții de utilizare.
        Serviciile noastre sunt disponibile printr-o Aplicație Web și una Mobilă
        disponibilă pentru telefoanele mobile ce rulează sistem de operare
        Android sau iOS.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        <strong>I.</strong> PREZENTARE GENERALĂ ȘI ASPECTE JURIDICE
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>I.1.</strong> Acești Termeni și Condiții de Utilizare formează
        baza contractuală dintre S.C. Green Rent SRL și client pentru utilizarea
        Aplicației Web și Aplicației Mobile Cultivá (astfel cum sunt definite
        mai jos) precum și pentru orice servicii asociate. Dacă nu sunteți de
        acord sau nu acceptați, fără limitare, Termenii și Condițiile de
        utilizare ale Aplicațiilor, vă rugăm să nu mai utilizați aceste
        Aplicații. Utilizarea Aplicațiilor echivalează cu acceptarea Termenilor
        și Condițiilor în totalitate.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>I.2.</strong> S.C. Green Rent SRL cu sediul în Iași,
        înregistrată la Oficiul Registrului Comerțului de pe lângă Tribunalul
        Iași sub nr. J22/2947/2019, având CUI 41639487 este proprietarul
        Aplicației Web și al Aplicației Mobile Cultivá.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>I.3.</strong> Acești termeni și condiții de utilizare se aplică
        persoanelor juridice și persoanelor fizice care au împlinit vârsta de 16
        ani sau peste, care accesează/utilizează Aplicația Web și/sau Aplicația
        Mobilă Cultivá. Acești termeni și condiții se aplică și persoanelor care
        au împlinit vârsta de 15 ani și au un contract de muncă valabil încheiat
        cu unul din clienții persoane juridice care folosesc Aplicația Web /
        Aplicația Mobilă și care la rândul lor utilizează Aplicația Web /
        Aplicația Mobilă.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>I.4.</strong> Serviciile sunt disponibile pe Aplicația Web{" "}
        <a href="http://cultivaapp.ro">http://cultivaapp.ro</a> și prin
        intermediul Aplicației Mobile Cultivá (denumite în continuare împreună
        ”Aplicația”). Aplicația pentru dispozitivele mobile este disponibilă în
        Google Play Store sau App Store.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>I.5.</strong> Aplicația Cultivá este un serviciu destinat
        fermierilor care furnizează acestora date despre cât, unde și cum a
        lucrat un utilaj agricol într-o anumită zi precum și alte date legate de
        utilizarea acestuia și de angajatul care îl manevrează/conduce. Totodată
        sunt furnizate prin intermediul Aplicației date furnizate de APIA
        (agenția de plăți și Intervenții pentru agricultură), număr parcele,
        identificarea și poziționarea acestora.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>I.6.</strong> S.C. Green Rent SRL își rezervă dreptul să
        modifice și să completeze oricând conținutul serviciilor furnizate
        sau/și să sisteze oricând prestarea tuturor sau a anumitor servicii din
        cadrul Aplicației, fără notificare prealabilă, fără îndeplinirea nici
        unei condiții suplimentare și fără acord suplimentar, fără ca cealaltă
        parte contractuală să poată invoca pretenții cu privire la acest aspect.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>I.7.</strong> S.C. Green Rent SRL poate, fără notificare
        prealabilă și fără a fi necesară îndeplinirea vreunei condiții, să
        rezilieze contractul descris în acești Termeni și Condiții de utilizare,
        precum și toate serviciile conexe. Rezilierea poate fi făcută în orice
        moment și fără Notificare prealabilă. În acest caz S.C. Green Rent SRL
        poate indica serviciul sau serviciile care nu mai sunt disponibile și
        poate restricționa sau suspenda toate sau o parte din serviciile
        (inclusiv disponibilitatea oricăror date, baze de date sau conținut),
        poate suspenda accesul în Aplicație sau poate refuza furnizarea
        serviciilor în integralitate sau parțial și poate bloca sau șterge toate
        sau o parte din datele asociate, în special contul și datele
        dumneavoastră.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>I.8.</strong> Pentru a rezilia prezentul contract din inițiativa
        dumneavoastră este necesar să transmiteți o Notificare în acest sens pe
        adresa de e-mail:{" "}
        <a href="mailto:info@cultivaapp.ro">info@cultivaapp.ro</a>, cu 10 zile
        calendaristice anterior datei la care doriți să aibă loc încetarea
        contractului. Până la această dată contractul va fi considerat în
        vigoare și toate obligațiile aferente acestuia, active.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>I.9.</strong> S.C. Green Rent SRL poate, de asemenea, să
        modifice conținutul acestor Termeni și Condiții, poate să modifice
        serviciul sau versiunea Aplicației și vă poate solicita să confirmați
        acceptarea noilor Termeni și Condiții sau a noilor servicii furnizate cu
        o confirmare în aplicație. În acest caz puteți continua să utilizați
        serviciul sau Aplicația numai dacă sunteți de acord cu modificările.
        Nici o propunere de modificare a prezentelor Termeni și Condiții nu va
        intra în vigoare și nu va produce efecte decât dacă va fi acceptată de
        S.C. Green Rent SRL și va fi implementată printr-o procedură de
        modificare a acestora.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>I.10.</strong> Colaborăm cu diverși furnizori de servicii pentru
        a oferi bunuri și servicii iar colectarea și prelucrarea datelor cu
        caracter personal în legătură cu serviciul se efectuează în conformitate
        cu Politica noastră de Confidențialitate.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        <strong>II.</strong> SERVICII FURNIZATE PRIN INTERMEDIUL APLICAȚIEI
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>II.1.</strong> Serviciile furnizate prin intermediul aplicației
        ajută la înregistrarea și monitorizarea activităților realizate în
        cadrul organizației beneficiarilor, ajutând la managementul resurselor,
        evaluarea costurilor și profiturilor. În acest sens, serviciile includ:
      </Typography>
      <Typography component="ul" sx={{ pl: 4 }}>
        <Typography component="li" variant="body1">
          înregistrarea fermei beneficiarului,
        </Typography>
        <Typography component="li" variant="body1">
          înregistrarea locației fermei
        </Typography>
        <Typography component="li" variant="body1">
          înregistrarea terenurilor aflate în administrare
        </Typography>
        <Typography component="li" variant="body1">
          înregistrarea utilajelor administrate
        </Typography>
        <Typography component="li" variant="body1">
          înregistrarea implementurilor și a tuturor obiectelor folosite în
          realizarea activităților agricole care se doresc a fi monitorizate
        </Typography>
        <Typography component="li" variant="body1">
          înregistrarea personalului fermei
        </Typography>
        <Typography component="li" variant="body1">
          montarea unui dispozitiv pe utilajul agricol
        </Typography>
        <Typography component="li" variant="body1">
          înregistrarea traseului parcurs de utilajul agricol
        </Typography>
        <Typography component="li" variant="body1">
          logarea șoferului în aplicație în vederea asocierii acestuia cu
          lucrarea efectuată
        </Typography>
        <Typography component="li" variant="body1">
          afișarea traseelor lucrărilor agricole efectuate
        </Typography>
        <Typography component="li" variant="body1">
          interogarea lucrărilor agricole pe o serie de factori precum perioada,
          utilajul, angajatul
        </Typography>
        <Typography component="li" variant="body1">
          afișarea consumului efectuat în funcție de diferite criterii de la
          lucrare, angajat, utilaj, perioadă de timp
        </Typography>
        <Typography component="li" variant="body1">
          calcularea suprafețelor lucrate
        </Typography>
        <Typography component="li" variant="body1">
          completarea automată și livrarea fișelor de consum și bonurilor de
          ordine
        </Typography>
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        <strong>III.</strong> CONTUL DE UTILIZATOR
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>III.1</strong> Pentru ca dumneavoastră să puteți utiliza
        serviciul sau Aplicația, trebuie să vă conectați și să creați un cont de
        utilizator ca și fermier. Vă puteți crea contul în Aplicația WEB{" "}
        <a href="http://cultivaapp.ro">http://cultivaapp.ro</a> sau în aplicația
        ce poate fi descărcată pe telefonul mobil. La înregistrare, furnizați
        către S.C. Green Rent SRL datele cu caracter personal respectiv nume,
        adresă de e-mail, date societate comercială și detaliile cardului de
        credit (detaliile cardului de credit nu vor fi salvate de către
        Aplicație sau de către S.C. Green Rent SRL). După înregistrarea cu
        succes, veți beneficia de un cont personal de utilizator de la
        Aplicație, pe care îl puteți accesa cu parolă la alegere. Totodată,
        pentru o mai bună configurare a contului vă putem solicita să ne oferiți
        mai multe informații, cum ar fi localitatea unde se află situată ferma,
        numărul de angajați, numărul și tipul utilajelor și implementurilor
        agricole deținute, obiectul de activitate etc....
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>III.2</strong> Contul de utilizator pentru persoana fizică ca
        angajat în cadrul unei ferme se va realiza de către angajat la cererea
        fermierului.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>III.3 </strong>Conturile de utilizator sunt disponibile pentru
        persoanele juridice și fizice identificate fără dubiu. S.C. Green Rent
        SRL stochează datele dumneavoastră în contul de utilizator. Contul de
        utilizator reunește informații despre dumneavoastră/societatea
        dumneavoastră , astfel încât dumneavoastră și noi să putem înțelege și
        gestiona mai bine drepturile și obligațiile în calitate de utilizator al
        Aplicatiei.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>III.4 </strong>Un cont de utilizator nu este transferabil
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>III.5 </strong>Atunci când vă creați contul de utilizator,
        garantați că informațiile pe care le furnizați sunt corecte, complete și
        actualizate și confirmați că S.C. Green Rent SRL nu are control asupra
        acestor date, corectitudinea acestora fiind astfel propria
        responsabilitate. Pentru buna funcționare a aplicației și a facturării
        serviciilor în condițiile prezentate în Termeni și condiții, toate
        datele trebuie să fie furnizate în mod corect.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>III.6 </strong>Este necesar ca pe toată durata existenței
        contului să mențineți datele de identificare și contact corecte,
        complete și actualizate. Vă rugăm să utilizați funcția de utilizare a
        datelor din contul de utilizator pentru orice modificări. În cazul în
        care datele dumneavoastră nu mai sunt corecte, complete și actualizate,
        și pe cale de consecință, comunicarea cu dumneavoastră ( și în special
        efectuarea plăților ) nu mai este posibilă din cauza informațiilor de
        contact nevalidate, incorecte sau incomplete sau din alte motive ( cum
        ar fi : metoda de plată este nevalidă sau expirată) este posibil să nu
        se mai realizeze procesarea serviciului în aplicație, este posibil să nu
        mai aveți acces la serviciile oferite, să nu le mai puteți folosi și ne
        rezervăm dreptul să anulăm, suspendăm sau să intrerupem furnizarea
        serviciilor
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>III.7 </strong>Sunteți responsabil pentru toate activitățile din
        contul dumneavoastră de utilizator și este obligatoriu să vă păstrați
        întotdeauna numele de utilizator și parola confidențiale și în
        siguranță.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>III.8 </strong>S.C. Green Rent SRL are în orice moment dreptul
        de a verifica informațiile pe care le furnizați și de a refuza serviciul
        sau utilizarea Aplicației fără a justifica decizia.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>III.9 </strong>Ne rezervăm dreptul de a vă anula și șterge
        contul de utilizator în orice moment, în special dacă nu vă mai putem
        contacta prin adresa de e-mail specificată în contul de utilizator.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        <strong>IV.</strong> UTILIZAREA SERVICIULUI
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>IV.1 </strong>Pentru a utiliza serviciul sau Aplicația și a crea
        un cont de utilizator, trebuie să aveți 16 ani sau mai mult. Astfel,
        confirmați că îndepliniți toate condițiile legale cerute pentru
        incheierea valabilă a aunui contract, înclusiv că aveți discernământ.
        Prin confirmarea în secțiunea corespunzătoare din Aplicație,
        Utilizatorul confirmă că are 16 ani. Dacă aveți sub 16 ani, este
        interzisă utilizarea aplicației. Ne rezervăm dreptul să vă solicităm
        dovada că ați împlinit vârsta de 16 ani iar în caz de suspiciune să
        închidem un astfel de cont.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>IV.2 </strong>Dacă aveți vârsta cuprinsă între 16 și 18 ani iar
        reprezentantul dumneavoastră legal nu este de acord cu Termenii și
        condițiile de utilizare a Aplicației, vă obligați să încetați utilizarea
        acesteia și să cereri S.C. Green Rent SRL. să șteargă contul pe care
        l-ați creat. Se poate solicita ștergerea contului în orice moment prin
        trimiterea unui e-mail la info@cultivaapp.ro. Vă rugăm să includeți în
        mesaj următoarele date pentru a putea identifica și șterge contul :
        numele de utilizator.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>IV.3 </strong>Vă angajați să respectați prevederile reglementate
        la punctele 4.1 si 4.2. precum și orice alte obligatii cuprinse in
        prezentele Termeni si Condiții.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>IV.4 </strong>S.C. Green Rent SRL își rezervă dreptul de a
        rezilia imediat serviciul și utilizarea Aplicației dacă nu îndepliniți
        toate obligațiile stabilite mai sus.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>IV.5 </strong>Sunteți responsabil pentru instalarea și
        functionarea corespunzătoare a Aplicației de pe dispozitivele utilizate
        de dumneavoastră și pentru accesul necesar la rețeaua de date pentru a
        utiliza serviciul și Aplicația, precum și orice costuri care pot apărea
        din utilizarea serviciului și a Aplicației. În timp de accesați și/sau
        utilizați serviciul și Aplicația, pot apărea taxe și comisioane de date
        și de conectare iar dumneavoastră sunteți unicul responsabil pentru
        plata acestora.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>IV.6 </strong>Vă asumați responsabilitatea pentru achiziționarea
        și actualizarea hardware-ului și dispozitivelor compatibile care sunt
        necesare pentru accesul la serviciu și Aplicație, precum și pentru
        instalarea actualizărilor pentru utilizarea corespunzătoare. S.C. Green
        Rent SRL nu garantează că serviciul, aplicația sau părți ale acesteia
        vor funcționa pe orice hardware sau dispozitiv. În plus pot apărea
        defecțiuni și întârzierI în servicii și în Aplicație, care sunt cauzate
        de modalitatea de funcționare a internetului și a mijloacelor de
        comunicare electronică și care nu pot fi influențate de noi.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>IV.7 </strong>Vă asumați responsabilitatea să verificați și să
        vă asigurați că ați descărcat Aplicația actualizată pentru dispozitivul
        dumneavoastră. Acest aspect este deosebit de important atunci când sunt
        publicate versiuni mai noi ale aplicației iar S.C. Green Rent nu este
        obligat să mențină funcționale și fără erori, versiuni mai vechi .
        Pentru a vă asigura că utilizați toate funcțiile Aplicației este necesar
        să aveți actualizată cu ultima versiune Aplicația, actualizare care este
        strict obligația dumneavoastră, S.C. Green Rent neputând fi tras la
        răspundere pentru o funcționare neconforma a serviciului sau a
        Aplicației în această situație.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>IV.8 </strong>S.C. Green Rent SRL își rezervă dreptul de a anula
        serviciul sau de a anula disponibilitatea Aplicației dacă există o
        utilizare a acestora cu un dispozitiv incompatibil sau nepermis.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        <strong>V.</strong> PLATA
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>V.1 </strong>Pentru utilizarea serviciului și a Aplicației se va
        achita suma comunicata prin intermediul Aplicației sau/si cea agreata
        prin Contractul de prestari servicii incheiat cu S.C. Green Rent S.R.L.,
        de către persoanele juridice/persoanele fizice inregistrate cu cont de
        utilizator fermier. Obligatia de plată a acestei sume se naște din
        momentul în care vă creați contul de utilizator și acesta este validat.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>V.2 </strong>Prețul actualizat se va comunica in cadrul
        Aplicației sau direct cleintului, la datele de contact comunicate,
        anterior efectuării plății. SC Green Rent SRL își rezervă dreptul de a
        modifica costul perceput pentru utilizarea serviciului/ Aplicației ori
        de câte ori acest lucru este necesar. Aveți obligația să verificați
        costul perceput anterior efectuării plății și începerii utilizării
        serviciului și a Aplicației. Prin continuarea utilizării serviciului și
        a Aplicației vă dați acordul fără limitări cu privire la costul perceput
        de către S.C. Green Rent SRL. , fără a putea invoca pretenții cu privire
        la acest aspect.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>V.3 </strong>Plata se va face prin intermediul procesatorului de
        plăți care este conectat la Aplicația Cultivá.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>V.4 </strong>Prin acceptare prezentelor Termeni și condiții
        și/sau prin Utilizarea Serviciului/Aplicatiei vă dați acordul pentru
        plata prețului, astfel cum este specificat in Aplicatie , prin
        intermediul Procesatorului de plăți care vă va debita cardul de
        debit/credit sau va utiliza orice altă metodă de plată pe care ași
        specificat-o atunci când v-ați înregistrat. Vă obligați să furnizați un
        card de debit/credit valabil sau a unei alte metode plată pentru plata
        la timp a tuturor costurilor datorate către S.C. Green Rent SRL.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>V.5 </strong>S.C. Green Rent SRL utilizează un Procesator de
        plăți extern (”Procesatorul de plăți”) prin intermediul Aplicației
        pentru a vă conecta cardul de debit/credit la Aplicație și Servicii.
        Procesarea plăților în legătură cu utilizarea de către dumneavoastră a
        aplicației și a serviciului este supusă Termenilor și Condițiilor și
        Politicii de Confidențialitate a procesatorului de plăți, precum și a
        emitentului cardului dumneavoastră, în plus față de prezentele Termeni
        și condiții . S.C. Green Rent SRL nu este responsabil pentru erorile
        comise de procesatorul de plăți sau de emitentul cardului de credit. În
        legătură cu utilizarea de către dumneavoastră a serviciilor Aplicatiei
        Cultivá, S.C. Green Rent SRL obtine anumite detalii ale transzacției pe
        care le va utiliza strict în conformitate cu Politica de
        Confidențialitate.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        <strong>VI.</strong> LICENȚĂ ȘI RESTRICȚII
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>VI.1 </strong>Cu condiția respectării în totalitate a
        prezentelor Termeni și Condiții de utilizare, S.C. Green Rent SRL vă
        acordă o sub-licență restricționată, neexclusivă, revocabilă care nu
        poate fi sub-licențiată sau transferată pentru : (1) a accesa aplicația
        și a o utiliza pe propiul dispozitiv în legătura cu utilizarea
        serviciului și pentru (ii) a accesa tot conținutul, informațiile și
        materialele asociate puse la dispoziție prin intermediul aplicației și a
        le utiliza în mod privat și necomercial.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>VI.2 </strong>Serviciile și aplicația sunt proprietatea S.C.
        Green Rent SRL. S.C. Green Rent SRL deține toate drepturile de
        proprietate intelectuală inclusiv drepturile de autor in legătură cu
        serviciile și Aplicația, orice încălcare a acestora care nu respectă
        prezentele Termeni și Condiții de utilizare urmând să fie sancționată
        conform Legii Române, S.C. Green Rent SRL rezervându-și dreptul să
        solicite daune pentru acoperirea în integralitate a prejudiciului
        cauzat.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>VI.3 </strong>Este interzis și vă obligați (1) Să nu eliminați
        referințele la drepturi de autor, mărci comerciale su alte dreptrui de
        proprietate din aplicație ; (ii) să nu repreduceți, modificați, creați,
        distribuiți, licențiați, închiriați, vindeți, reveindeți, transferați,
        transmiteți, publicați sau să nu folosiți într-un mod neautorizat
        aplicația făro consimțământul expres și în scris al S. C. Green Rent
        SRL. (iii) să nu decompilați, inversați sau să dezasamblați aplicația;
        (iv) să nu legați, încadrați sau duplicați o parte a aplicației ; (v) să
        nu dezvoltați sau să instalați programe sau scrispturi cu scopul de a
        extrage, indexa, controla sau efectua alte extrageri de date sau de a
        restricționa sau impiedica în mod nejustificat funcționarea aplicației
        sau a unor componente ale acesteia; (vi) să nu încercați să obțineți sau
        să nu interferați, prin acces neautorizat la orice parte a Aplicației și
        a sistemelor sau rețelelor conectate la aceasta. În cazul nerespectării
        acestor obligații , S.C. Green Rent SRL are dreptul de a cere daune
        interese pentru acoperirea în integralitate a prejudiciului cauzat.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>VI.4 </strong>Acesți termeni și condiții de utilizare precum și
        utilizarea Serviciului și a Aplicației nu vă acordă nici un drept (i)
        asupra sau în legătură cu Serviciul sau Aplicația, cu excepția licenței
        limitate acordate mai sus (ii) pentru utilizarea sau referirea la numele
        companiei S.C. Green Rent SRL, Aplicatia Cultivá, logo-uri, mărci, alte
        mărci comerciale , numele aplicației s.a.m.d.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>VI.5 </strong>Serviciul sau Aplicația pot fi accesibile sau
        utilizate în legătură cu servicii și/sau conținut de la terți ( inclusiv
        publicitate ). Prin acceptarea acestor Termeni și condiții și/sau prin
        utilizarea Serviciilor și a Aplicației , sunteți de acord că pentru
        serviciile acestora se vor aplica Termenii și Condițiile și Politicile
        de Confidențialitate ale terților. S.C. Green Rent SRL nu este
        responsabila în nici o situație și sub nici o formă pentru nici un fel
        de bunuri, servicii sau conținut de la terți.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        <strong>VII.</strong> CONȚINUTUL UTILIZATORULUI
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>VII.1 </strong>S.C Green Rent SRL vă poate permite, în funcție
        de voința acestuia, să trimiteți, să încărcați, să publicați sau să
        încărcați periodic conținut text, audio și/sau de imagine și informații
        care să fie disponibile prin intermediul aplicației pentru S.C. Green
        Rent SRL . În acest sens S.C. Green Rent SRL va permite utilizatorilor
        să facă comentarii și să ofere feedback cu privire la servicii, cereri
        de asistență sau contribuții ale utilizatorului (”Conținutul
        Utilizatorului”). Ca regulă, veți fi proprietarul întregului conținut de
        utilizator trimis sau pus la dispoziție în orice mod.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>VII.2 </strong>Vă obligați să vă asigurați că : (i)sunteți fie
        proprietarul unic și exclusiv al întregului conținut al utilizatorului,
        fie aveți toate drepturile, licențele, consimțămintele și aprobările
        necesare pentru a furniza conținutul de utilizator sub licența de mai
        sus către S.C. Green Rent SRL și (ii) nici conținutul utilizatorului ,
        nici transmiterea, încărcarea, publicarea sau altă divulgare a acestuia,
        nici utilizarea conținutului uitilizatorului de către S.C. . nu încalcă
        drepturile terților în ceea ce privește proprietatea intelectuală sau
        alte drepturi ale acestora, drepturi de publicare sau drepturi ce țin de
        viața privată sau de protecție a datelor sau ignoră sau încalcă legile
        sau reglementările aplicabile atât la nivel național cât și european
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>VII.3 </strong>Vă obligați să nu postați, transmitețăi, să
        încărcați, să comunicați, să publicați sau să puneți la dispoziție
        niciun conținut al utilizatorului care este ofensator, defăimător,
        violent, obscen, pornografic, sau în orice alt mod inacceptabil sau
        ilegal. S.C. Green Rent SRL va evalua conținutul iar evaluarea se va
        face indiferent dacă conținutul utilizatorului este protejat de lege sau
        nu. S.C. Green Rent SRL are dreptul, fara a vă notifica, de a revizui,
        monitoriza și elimina conținutul utilizatorului la alegerea sa.
        Subliniem faptul că nu există o obligație a S.C. Green Rent SRL de a
        verifica, monitoriza sau elimina conținutul utilizatorului.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        <strong>VIII.</strong> DESPĂGUBIRI
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>VIII.1 </strong>S.C. Green Rent SRL precum și persoanele fizice
        din carul acesteia sunt exonerate , împotriva tuturor revendicărilor,
        costurilor, daunelor, pierderilor, cererilor de răspundere ( inclusiv
        onorariile și costurile avocaților ) care rezultă din sau sunt în
        legătură cu încălcarea de către dumneavoastră a oricărei prevederi a
        acestor Termeni și Condiții de utilizare sau/și a oricăror prevederi
        legale aplicabile, incălcarea drepturilor terților, sau în orice alt mod
        sau în legătură cu utilizarea serviciului și/sau a rezultatului
        Aplicației. În oricare din aceste situații vă angajați să despăgubiți
        partea îndreptărțită în integralitate fără a putea ridica nici un fel de
        pretenții față de S.C. Green Rent SRL
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        <strong>IX.</strong> DECLINAREA RESPONSABILITĂȚII ȘI GARANȚIA
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>IX.1 </strong>Orice garanție a S.C. Green Rent SRL pentru
        servicii și Aplicație este exclusă. S.C. Green Rent SRL nu oferă nici o
        asigurare cu privire la cereri și servicii și nu constituie sau oferă
        nici o granație.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>IX.2 </strong>Serviciul este furnizat ” CA ATARE” și ”DUPĂ CUM
        ESTE DISPONIBIL”
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>IX.3 </strong>S.C. Green Rent SRL Nu poate garanta că site-ul
        web, conținutul său, serviciul sau/și Aplicația sunt lipsite de erori,
        defecte, malware și viruși sau că acestea sunt corecte, actualizate și
        fără erori.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>IX.4 </strong>S.C. Green Rent SRL nu face nici o declarație și
        nu oferă nicio garanție că serviciul nu va fi întrerupt sau nu este
        lipsit de erori în alt mod și nici nu oferă o garanție cu privire la
        fiabilitatea, calitatea, adecvarea sau disponibilitatea serviciului și a
        Aplicației sau a altor servicii în legătură cu Serviciile și Aplicația.
        Prin intermediul acestor Termeni și condiții vă informăm că pot apărea
        întreruperi sau erori temporare, iar serviciile și aplicația pot fi
        supuse unor restricții, defecțiuni, întârziere sau alte probleme.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        <strong>X.</strong> DECLINAREA RESPONSABILITĂȚII și LIMITAREA
        RĂSPUNDERII
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>X.1 </strong>S.C. Green Rent SRL răspunde doar pentru daunele
        cauzate cu intenție sau neglijență gravă.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>X.2 </strong>S.C. Green Rent SRL nu este responsabil pentru nici
        o daună rezultată din utilizarea ( sau incapacitatea de a utiliza )
        site-ul web , Aplicația sau serviciile, inclusiv pentru prejudicii
        cauzate de malware, viruși sau orice erori sau lipsuri ale
        informațiilor.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>X.3 </strong>S.C. Green Rent SRL nu este responsabil pentru
        prejudicii sau pretenții ale terților
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>X.4 </strong>Aceste exonerări de răspundere și limitări se
        aplică în aceleași limite și colaboratorilor, angajaților,
        reprezentanților și asociaților S.C. Green Rent SRL.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        <strong>XI.</strong> LEGEA APLICABILĂ ȘI ALTE DISPOZIȚII
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>XI.1 </strong>Acești termeni și condiții sunt supuși Legislației
        române în vigoare, cu excluderea oricăror trimiteri la alte sisteme
        juridice . În cazul unei neînțelegeri, părțile vor încerca remedierea pe
        cale amiabilă. În cazul unui litigiu sunt competente instanțele române
        de la sediul S.C. Green Rent SRL
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>XI.2 </strong>În cazul în care unele clauze ale acestor Termeni
        și Condiții de utilizare sau ale Contractului sunt declarate nevalide
        sau ineficiente, eficacitatea celorlalte clauze ale contractului nu va
        fi afectată. În măsură permisă de lege, dispozițiile nule sau
        ineficiente vor fi înlocuite cu dispoziții acre se apropie cât mai mult
        posibil de scopul lor economic.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>XI.3 </strong>Este interzisă tranferarea drepturilor și
        obligațiilor conferite conform acestor termeni și Condiții de utilizare,
        către o terță persoană, fără consimțământul prealabil, expres și în
        scris a S.C. Green Rent SRL
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>XI.4 </strong>S.C. Green Rent SRL vă poate trimite informații
        despre Serviciu și Aplicație, actualizări și oferte exclusive prin
        notificări sau prin e-mail transmis la adresa dvs de e-mail stocată în
        contul de utilizator, în condițiile prevăzute în Politica de
        Confidențialitate
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>XI.5 </strong>Utilizatorul confirmă că a citit și acceptat
        acești Termeni și Condiții. Utilizarea aplicației și/sau a serviciilor
        site-ului de către Utilizator echivalează cu acceptarea necondiționată a
        acestor Termeni și Condiții
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>XI.6 </strong>Dacă nu sunteți de acord cu acesti Termeni și
        condiții, vă rugăm să nu utilizați site-ul și/sau aplicația.
      </Typography>
    </Box>
  );
};
