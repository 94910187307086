import React, { ReactElement } from "react";
import { Grid, Typography, Box } from "@mui/material";
import video1 from "../assets/video/video1.mp4";
import video2 from "../assets/video/video1.mp4";
import video3 from "../assets/video/video1.mp4";
import video4 from "../assets/video/video1.mp4";

interface ExploreObject {
  video: string;
  title: string;
}

export const ExploreComponent = (props: {
  id: string;
}): ReactElement | null => {
  const items: ExploreObject[] = [
    {
      video: video1,
      title: "Informație la secundă",
    },
    {
      video: video2,
      title: "Algoritmi de calcul automat",
    },
    {
      video: video3,
      title: "Înregistrări automate",
    },
    {
      video: video4,
      title: "Rapoarte vizuale",
    },
  ];

  return (
    <>
      <Grid container id={props.id} justifyContent={"center"} sx={{ mt: 10 }}>
        <Grid item textAlign="center">
          <Box
            sx={{
              padding: { xs: "40px 100px", md: "40px 160px" },
            }}
          >
            <Typography className="typography-orange" sx={{ fontSize: "24px" }}>
              Complet validate
            </Typography>
            <Typography
              className="text-black-b"
              sx={{
                fontSize: { md: "32px", xs: "20px" },
                fontFamily: "Manrope",
              }}
            >
              Explorează Inovațiile Tehnologice
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"center"}
        spacing={1}
        sx={{ mt: 5, mb: 20 }}
      >
        {items.map((element, index) => (
          <Grid item key={index}>
            <Box
              sx={{
                position: "relative",
                ml: 1,
                width: { md: "320px", xs: "320px" },
                height: "655px",
                overflow: "hidden",
                borderRadius: "16px",
                "&:hover .overlay, &:hover .title": {
                  opacity: 0,
                  transition: "opacity 0.3s ease-in-out",
                },
              }}
              onMouseEnter={(e) => {
                const video = e.currentTarget.querySelector(
                  "video"
                ) as HTMLVideoElement;
                video.play();
              }}
              onMouseLeave={(e) => {
                const video = e.currentTarget.querySelector(
                  "video"
                ) as HTMLVideoElement;
                video.pause();
                video.currentTime = 0;
              }}
            >
              <video
                src={element.video}
                muted
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <Box
                className="overlay"
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "200px",
                  background:
                    "linear-gradient(to top, rgba(0, 0, 0, 0.7),  rgba(0, 0, 0, 0))",
                  transition: "opacity 0.3s ease-in-out",
                }}
              />
              <Typography
                className="title"
                sx={{
                  position: "absolute",
                  bottom: 15,
                  left: 25,
                  color: "white",
                  padding: "8px",
                  fontSize: "30px",
                  fontWeight: "bold",
                  width: "70%",
                  transition: "opacity 0.3s ease-in-out",
                }}
              >
                {element.title}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
